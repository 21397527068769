import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'BoDashboard',
    component: () => import('@/views/BoDashboard.vue'),
    meta: {
      pageTitle: 'Dashboard',
    },
  },
  {
    path: '/home',
    name: 'BoHome',
    component: () => import('@/views/BoHome.vue'),
    meta: {
      pageTitle: 'Home'
    }
  },
  {
    path: '/about',
    name: 'BoAboutUs',
    component: () => import(/* webpackChunkName: "about" */ '@/views/BoAboutUs.vue'),
    meta: {
      pageTitle: 'About Us',
    }
  },
  {
    path: '/service/:slug?',
    name: 'BoService',
    component: () => import(/* webpackChunkName: "about" */ '@/views/BoService.vue'),
    meta: {
      pageTitle: 'Service',
      hasCta: true,
      objName: 'Service'
    }
  },
  {
    path: '/add-service',
    name: 'BoAddService',
    component: () => import(/* webpackChunkName: "about" */ '@/views/BoAddService.vue'),
    meta: {
      pageTitle: 'Add Service',
      hasCta: true,
      objName: 'Add Service'
    }
  },
  {
    path: '/contact',
    name: 'BoContact',
    component: () => import(/* webpackChunkName: "about" */ '@/views/BoContact.vue'),
    meta: {
      pageTitle: 'Contact',
      hasCta: true,
      objName: 'Contact'
    }
  },
  {
    path: '/contact-list',
    name: 'BoContacMessage',
    component: () => import(/* webpackChunkName: "about" */ '@/views/BoContacMessage.vue'),
    meta: {
      pageTitle: 'Contact Message',
      hasCta: true,
      objName: 'Contact Message'
    }
  },
  {
    path: '/user',
    name: 'Users',
    component: () => import(/* webpackChunkName: "user" */ '@/views/BoUser.vue'),
    meta: {
      pageTitle: 'User List',
      hasCta:'true',
      objName: 'User'
    },
  },
   {
    path: '/add-user',
    name: 'BoAddUser',
    component: () => import(/* webpackChunkName: "user" */ '@/views/BoAddUser.vue'),
    meta: {
      pageTitle: 'Add User',
      hasCta:'true',
      objName: 'Add User'
    },
  },
  {
    path: '/add-level',
    name: 'BoAddLevel',
    component: () => import(/* webpackChunkName: "user" */ '@/views/BoAddLevel.vue'),
    meta: {
      pageTitle: 'Add Level',
      hasCta:'true',
      objName: 'Add Level'
    },
  },
   {
    path: '/client-list/:slug?',
    name: 'ClientList',
    component: () => import(/* webpackChunkName: "user" */ '@/views/ClientList.vue'),
    meta: {
      pageTitle: 'Clients List',
      hasCta:'true',
      objName: 'Client'
    },
  },
  {
    path: '/user',
    name: 'BoUserLevel',
    component: () => import(/* webpackChunkName: "add-user" */ '@/views/BoUserLevel.vue'),
     meta: {
      pageTitle: 'User Level List',
      hasCta:'true',
      objName: 'User Level'
    },
  },
  {
    path: '/auditrail',
    name: 'AuditTrail',
    component: () => import('@/views/AuditTrail.vue'),
    meta: {
      pageTitle: 'Audit Trail'
    }
  }, 
  {
    path: '/inqiury',
    name: 'BoInquiry',
    component: () => import('@/views/BoInquiry.vue'),
    meta: {
      pageTitle: 'Inquiry'
    }
  }, 
  {
    path: '/legal/:slug?',
    name: 'BoLegal',
    component: () => import('@/views/BoLegal.vue'),
    meta: {
      pageTitle: 'Legal'
    }
  },
   {
    path: '/edit-legal',
    name: 'BoEditLegal',
    component: () => import('@/views/BoEditLegal.vue'),
    meta: {
      pageTitle: 'Edit Legal'
    }
  }, 
  {
    path: '/article-list',
    name: 'ArticleList',
    component: () => import('@/views/ArticleList.vue'),
    meta: {
      pageTitle: 'Article List',
      objName: 'Article'
    }
  }, 
  {
    path: '/add-article',
    name: 'AddArticle',
    component: () => import('@/views/AddArticle.vue'),
    meta: {
      pageTitle: 'Add Article',
      hasCta: true,
      objName: 'AddArticle'
    }
  }, 
  {
    path: '/panduan-list',
    name: 'PanduanList',
    component: () => import('@/views/PanduanList.vue'),
    meta: {
      pageTitle: 'Panduan List',
      objName: 'Panduan List'
    }
  }, 
  {
    path: '/add-panduan',
    name: 'AddPanduan',
    component: () => import('@/views/AddPanduan.vue'),
    meta: {
      pageTitle: 'Add Panduan',
      hasCta: true,
      objName: 'AddPanduan'
    }
  }, 
  {
    path: '/kamus-keuangan',
    name: 'KamusKeuangan',
    component: () => import(/* webpackChunkName: "user" */ '@/views/KamusKeuangan.vue'),
    meta: {
      pageTitle: 'Kamus Keuangan List',
      hasCta:'true',
      objName: 'Kamus Keuangan List'
    },
  },
  {
    path: '/add-kamus',
    name: 'AddKamus',
    component: () => import(/* webpackChunkName: "user" */ '@/views/AddKamus.vue'),
    meta: {
      pageTitle: 'Add Kamus',
      hasCta:'true',
      objName: 'Add Kamus'
    },
  },
  {
    path: '/creditcard-list',
    name: 'CreditCardList',
    component: () => import('@/views/CreditCardList.vue'),
    meta: {
      pageTitle: 'Credit Card List',
      hasCta: true,
      objName: 'Credit Card List'
    }
  }, 
  {
    path: '/add-cc',
    name: 'AddCredit',
    component: () => import('@/views/AddCredit.vue'),
    meta: {
      pageTitle: 'Add Credit Card',
      hasCta: true,
      objName: 'Add Credit Card'
    }
  }, 
  {
    path: '/kta-list',
    name: 'KtaList',
    component: () => import('@/views/KtaList.vue'),
    meta: {
      pageTitle: 'KTA List',
      hasCta: true,
      objName: 'KTA List'
    }
  }, 
  {
    path: '/add-kta',
    name: 'AddKta',
    component: () => import('@/views/AddKta.vue'),
    meta: {
      pageTitle: 'Add KTA',
      hasCta: true,
      objName: 'Add KTA'
    }
  }, 
  {
    path: '/pinjaman-list',
    name: 'PinjamanOnlineList',
    component: () => import('@/views/PinjamanOnlineList.vue'),
    meta: {
      pageTitle: 'Pinjaman Online List',
      hasCta: true,
      objName: 'Pinjaman Online List'
    }
  }, 
  {
    path: '/add-pinjaman',
    name: 'AddPinjaman',
    component: () => import('@/views/AddPinjaman.vue'),
    meta: {
      pageTitle: 'Add Pinjaman',
      hasCta: true,
      objName: 'Add Pinjaman'
    }
  }, 
  {
    path: '/broker-forex',
    name: 'BrokerForexList',
    component: () => import('@/views/BrokerForexList.vue'),
    meta: {
      pageTitle: 'Broker Forex List',
      hasCta: true,
      objName: 'Broker Forex List'
    }
  }, 
  {
    path: '/add-broker-forex',
    name: 'AddBroker',
    component: () => import('@/views/AddBroker.vue'),
    meta: {
      pageTitle: 'Add Broker Forex',
      hasCta: true,
      objName: 'Add Broker Forex'
    }
  }, 
  
   {
    path: '/sekuritas-saham',
    name: 'SekuritasSaham',
    component: () => import('@/views/SekuritasSaham.vue'),
    meta: {
      pageTitle: 'Add Sekuritas Saham',
      hasCta: true,
      objName: 'Add Sekuritas Saham'
    }
  }, 
  {
    path: '/add-sekuritas',
    name: 'AddSekuritas',
    component: () => import('@/views/AddSekuritas.vue'),
    meta: {
      pageTitle: 'Add Sekuritas',
      hasCta: true,
      objName: 'Add Sekuritas'
    }
  },
  {
    path: '/ads-management',
    name: 'AdsManagement',
    component: () => import('@/views/AdsManagement.vue'),
    meta: {
      pageTitle: 'Ads Management',
      hasCta: true,
      objName: 'Ads Management'
  }
  }, 
  {
    path: '/article-category',
    name: 'ArticleCat',
    component: () => import('@/views/ArticleCat.vue'),
    meta: {
      pageTitle: 'Article Category',
      hasCta: true,
      objName: 'Article Category'
    }
  }, 
   {
    path: '/add-category',
    name: 'BoAddCategory',
    component: () => import('@/views/BoAddCategory.vue'),
    meta: {
      pageTitle: 'Add Category',
      hasCta: true,
      objName: 'Add Category'
    }
  }, 
  {
    path: '/kamus-category/:slug?',
    name: 'KamusCat',
    component: () => import('@/views/KamusCat.vue'),
    meta: {
      pageTitle: 'Kamus Category',
      hasCta: true,
      objName: 'Kamus Category'
    }
  }, 
  {
    path: '/subscribe',
    name: 'Subscribe',
    component: () => import('@/views/Subscribe.vue'),
    meta: {
      pageTitle: 'Subscribe',
      hasCta: true,
      objName: 'Subscribe'
    }
  }, 
  {
    path: '/comment',
    name: 'Comment',
    component: () => import('@/views/Comment.vue'),
    meta: {
      pageTitle: 'Comments',
      hasCta: true,
      objName: 'Comments'
    }
  }, 
  {
    path: '/schema-type',
    name: 'SchemaType',
    component: () => import('@/views/SchemaType.vue'),
    meta: {
      pageTitle: 'Schema Type',
      hasCta: true,
      objName: 'SchmaType'
    }
  }, 
  {
    path: '/add-schema',
    name: 'AddSchema',
    component: () => import('@/views/AddSchema.vue'),
    meta: {
      pageTitle: 'Add Schema Type',
      hasCta: true,
      objName: 'SchmaType'
    }
  }, 
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
