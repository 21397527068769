export default [
  {
    title: 'Dashboard',
    route: 'BoDashboard',
    icon:  'icon-speedometer',
  },
  {
    cap: 'Blog'
  },
  {
    title: 'Post',
    icon:  'ti-pencil-alt',
    children: [
      {
        title: 'All Post',
        route: 'ArticleList'
      },
      {
        title: 'Add New',
        route: 'AddArticle'
      },
       {
        title: 'Category',
        route: 'ArticleCat'
      },
    ]
  },
  /*{
    title: 'Panduan',
    icon:  'ti-write',
    children: [
      {
        title: 'All Post',
        route: 'PanduanList'
      },
      {
        title: 'Add New',
        route: 'AddPanduan'
      },
    ]
  },*/
 /* {
    title: 'Comments',
    route: 'Comment',
    icon: ' icon-bubbles',
  },*/
  {
    cap: 'Pages'
  },
  {
    title: 'Homepage',
    route: 'BoHome',
    icon:  'icon-home',
  },
   /*{
    title: 'Komparasi Produk',
    icon:  'ti-panel',
    children: [
      {
        title: 'Kartu Kredit',
        route: 'CreditCardList'
      },
      {
        title: 'KTA',
        route: 'KtaList'
      },
       {
        title: 'Pinjaman Online',
        route: 'PinjamanOnlineList'
      },
      {
        title: 'Broker Forex',
        route: 'BrokerForexList'
      },
      {
        title: 'Sekuritas Saham',
        route: 'SekuritasSaham'
      },
      {
        title: 'Aset Crypto',
        route: 'SekuritasSaham'
      },
       {
        title: 'Exchange',
        route: 'SekuritasSaham'
      },
    ]
  },*/
    
   
   /*{
    title: 'Contact Us',
    icon:  'icon-phone',
    children: [
      {
        title: 'Contact Page',
        route: 'BoContact'
      },
      {
        title: 'Contact Message',
        route: 'BoContacMessage'
      },
    ]
  },*/
   {
    title: 'Legal',
    route: 'BoLegal',
    icon:  'ti-bookmark-alt',
  },
  {
    cap: 'Setting'
  },
 
  /*{
    title: 'Schema Type',
    route: 'SchemaType',
    icon:  'fas fa-code-branch',
  },*/
  {
    title: 'Web Settings',
    icon: 'icon-settings',
    children: [
      {
        title: 'Pagination'
      },
    ]
  },
  {
    title: 'User Management',
    icon: 'icon-user-follow',
    children: [{
      title: 'User List',
      route: 'Users',
    },
    {
    title: 'User Level',
    route: 'BoUserLevel'}
    ],
    },
    {
    title: 'Audit Trail',
    route: 'AuditTrail',
    icon:  'icon-check',
    }
]