<template>
  <b-row class="page-titles">
    <b-col md="5" class="align-self-center">
      <h4 class="text-themecolor">{{ pageTitle || defPageTitle }}</h4>
    </b-col>
    <b-col md="7" class="align-self-center text-right">
      <div class="d-flex justify-content-end align-items-center">
        <b-breadcrumb>
          <b-breadcrumb-item :active="crumbs.length == 0">{{ pageTitle || defPageTitle }}</b-breadcrumb-item>
          <template v-if="crumbs.length > 0">
            <b-breadcrumb-item
              v-for="(crumb, key) in crumbs"
              :key="key"
              :active="key == (crumbs.length - 1)"
            >{{ crumb }}</b-breadcrumb-item>
          </template>
        </b-breadcrumb>
        <div class="ml-2">
          <slot>
            <b-button
              variant="success"
              v-if="!isList"
              class="m-l-15"
              :to="{ name: routeName }"
            >
              <i class="fas fa-list mr-2" />
              {{ objName }} List
            </b-button>
            <b-button
              variant="success"
              v-else
              class="m-l-15"
              :to="{ name: routeName, params: { slug: 'add' } }"
            >
              <i class="fa fa-plus-circle mr-2" />
              Add {{ objName }}
            </b-button>
          </slot>
        </div>
      </div>
    </b-col>
  </b-row>
</template>
<script>
import GlobalVue from '@/libs/Global.vue'
const _ = require('lodash')

export default {
  extends: GlobalVue,
  computed: {
    defPageTitle() { return _.startCase(this.$route.name) },
    crumbs() {
      let path = this.$route.path.split('/')
      let mapped = path.filter((el, k) => k > 1).map(el => _.startCase(el))
      return mapped
    }
  },
}
</script>