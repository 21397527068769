<template>
  <div id="main-wrapper" class="skin-green">
    <bo-header />
    <bo-sidebar />
    <router-view />
    <bo-footer />
  </div>
</template>
<script>
import BoFooter from '../components/BoFooter.vue'
import BoHeader from '../components/BoHeader.vue'
import BoSidebar from '../components/BoSidebar.vue'

export default {
  components: {
    BoHeader,
    BoSidebar,
    BoFooter,
  }
}
</script>