<script>
import BoCard from "../components/BoCard.vue"
import BoSearch from "../components/BoSearch.vue"
import BoPageTitle from '../components/BoPageTitle.vue'
import dummyData from '@/dummyData'

export default {
  components: {
    BoCard, BoSearch, BoPageTitle,
  },
  mounted() {
    console.log(this.pageTitle)
    console.log(this.hasCta)
  },
  data() {
    return {
      tableTransitionProps: {
        name: 'table-transition'
      },
      statusOptions: [
        { text: 'Active', value: 'Y' },
        { text: 'Inactive', value: 'N' },
      ],
      statusFilters: [
        { label: 'All Status', value: '' },
        { label: 'Active', value: 'Y' },
        { label: 'Inactive', value: 'N' },
      ],
      dummyData
    }
  },
  computed: {
    isMobile() { return window.innerWidth < 768 },
    isTablet() { return window.innerWidth >= 768 && window.innerWidth < 992 },
    isDesktop() { return !this.isMobile && !this.isTablet },
    pageTitle() { return this.$route.meta.pageTitle },
    breadcrumbs() { return this.$route.meta.breadcrumbs },
    objName() { return this.$route.meta.objName },
    hasCta() { return this.$route.meta.hasCta },
    isList() { return !this.$route.params.slug },
    isAdd() { return this.$route.params.slug == 'add' },
    routeName() { return this.$route.name },
  },
  methods: {
    deleteItem(index, dataSource) {
      this.$swal({
        icon: 'warning',
        title: 'Are you sure?',
        text: 'You cannot recover deleted data!',
        showCancelButton: true,
        confirmButtonText: 'Yes, delete it',
        cancelButtonText: 'No, cancel'
      }).then(result => {
        if (result.value) {
          dataSource.splice(index, 1)
          this.$swal({
            icon: 'success',
            title: 'Data deleted succesfully'
          })
        }
      })
    },
    changeStatus(index, dataSource) {
      let stats = dataSource[index].status == 'Y' ? 'Inactive' : 'Active'
      this.$swal({
        icon: 'warning',
        title: 'Change status to '+stats+'?',
        showCancelButton: true,
        confirmButtonText: 'Yes, change it',
        cancelButtonText: 'No, cancel'
      }).then(result => {
        if (result.value) {
          dataSource[index].status = dataSource[index].status == 'Y' ? 'N' : 'Y'
          this.$swal({
            icon: 'success',
            title: 'Status changed to '+stats
          })
        }
      })
    },
    cancelAddUpdate(method) {
      this.$swal({
        icon: 'warning',
        title: 'Are you sure?',
        text: 'Any changes you made will be lost!',
        showCancelButton: true,
        confirmButtonText: 'Yes, go back',
        cancelButtonText: 'No, cancel'
      }).then(result => {
        if (result.value) {
          method ? method() : this.$router.push({ name: this.$route.name })
        }
      })
    },
    confirmChanges(type, obj) {
      const objName = (obj ? obj : 'Data') + ' ' + (type == 'update' ? 'Updated' : 'Added')
      this.$swal({
        icon: 'success',
        title: objName+' Successfully.'
      })
    }
  },
}
</script>