import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import { BootstrapVue } from 'bootstrap-vue'
import PerfectScrollbar from 'vue2-perfect-scrollbar'
import 'vue2-perfect-scrollbar/dist/vue2-perfect-scrollbar.css'
import VueSweetalert2 from 'vue-sweetalert2'
import VWave from 'v-wave'
import vSelect from 'vue-select'
import 'sweetalert2/dist/sweetalert2.min.css'
import CKEditor from 'ckeditor4-vue'
import InputTag from 'vue-input-tag'
import VCalendar from 'v-calendar'

vSelect.props.components.default = () => ({
  OpenIndicator: {
    render: h => h('i', { class: 'fas fa-angle-down' })
  },
  Deselect: {
    render: h => h('i', { class: 'fas fa-times' })
  }
})

Vue.component('calendar', VCalendar)
Vue.component('input-tag', InputTag)
Vue.use( CKEditor )
Vue.component('v-select', vSelect)
Vue.config.productionTip = false

Vue.use(BootstrapVue)
Vue.use(PerfectScrollbar)
Vue.use(VWave)
Vue.use(VueSweetalert2)
Vue.use(VCalendar)
Vue.use(require('vue-moment'))

require('./assets/scss/style.scss')
import 'vue-select/dist/vue-select.css'


new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
